import React, { useState, useEffect } from 'react';
import {
    AlertTriangle,
    Database,
    Plane,
    Loader2
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '@/contexts/SettingsContext';
import { api } from '@/services/api';
import { toast } from '@/components/ui/use-toast';
import { Settings } from '@/services/types';

interface SettingsSectionProps {
    title: string;
    description: string;
    children: React.ReactNode;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({ title, description, children }) => (
    <div className="space-y-4 pb-6 mb-6 border-b last:border-b-0">
        <div>
            <h2 className="text-lg font-semibold">{title}</h2>
            <p className="text-sm text-gray-500">{description}</p>
        </div>
        {children}
    </div>
);

const DEFAULT_SETTINGS: Settings = {
    timezone: 'UTC',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: '24',
    baseAirport: 'ARN'
};

const AdminSettings = () => {
    const { token, isAuthenticated } = useAuth();
    const { updateSettings } = useSettings();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [baseAirportError, setBaseAirportError] = useState('');
    const [loadedSettings, setLoadedSettings] = useState<Settings | null>(null);

    useEffect(() => {
        const loadSettings = async () => {
            if (!token || !isAuthenticated) return;

            try {
                const response = await api.settings.getDateTime(token);
                setLoadedSettings(response);
            } catch (error) {
                console.error('Failed to load settings:', error);
                toast({
                    title: "Error",
                    description: "Failed to load settings",
                    variant: "destructive"
                });
            } finally {
                setIsLoading(false);
            }
        };

        loadSettings();
    }, [token, isAuthenticated]);

    const handleDeleteAllFlights = async () => {
        if (!token || !isAuthenticated) return;

        try {
            await api.flights.deleteAll(token);
            toast({
                title: "Success",
                description: "All flights deleted successfully"
            });
            setShowDeleteDialog(false);
        } catch (error) {
            console.error('Failed to delete flights:', error);
            toast({
                title: "Error",
                description: "Failed to delete flights",
                variant: "destructive"
            });
        }
    };

    const handleBaseAirportChange = (value: string) => {
        if (!loadedSettings) return;

        const upperValue = value.toUpperCase();
        setBaseAirportError('');

        if (upperValue.length > 3) {
            setBaseAirportError('IATA code must be exactly 3 letters');
            return;
        }

        if (upperValue !== '' && !/^[A-Z]*$/.test(upperValue)) {
            setBaseAirportError('Only uppercase letters are allowed');
            return;
        }

        setLoadedSettings(prev => prev ? {
            ...prev,
            baseAirport: upperValue
        } : null);
    };

    const handleBaseAirportSave = async () => {
        if (!token || !isAuthenticated || !loadedSettings) return;

        if (!/^[A-Z]{3}$/.test(loadedSettings.baseAirport)) {
            setBaseAirportError('Please enter a valid 3-letter IATA code');
            return;
        }

        setIsSaving(true);
        try {
            const response = await api.settings.updateDateTime(token, loadedSettings);
            setLoadedSettings(response);
            updateSettings(response);
            toast({
                title: "Success",
                description: `Base airport updated to ${response.baseAirport}`
            });
        } catch (error) {
            console.error('Failed to save base airport:', error);
            toast({
                title: "Error",
                description: "Failed to update base airport",
                variant: "destructive"
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleSettingChange = async (key: keyof Settings, value: string) => {
        if (!token || !isAuthenticated || !loadedSettings) return;

        setIsSaving(true);
        try {
            const newSettings = {
                ...loadedSettings,
                [key]: value
            };

            const response = await api.settings.updateDateTime(token, newSettings);
            setLoadedSettings(response);
            updateSettings(response);
            toast({
                title: "Success",
                description: `${key} updated successfully`
            });
        } catch (error) {
            console.error('Failed to save settings:', error);
            toast({
                title: "Error",
                description: `Failed to update ${key}`,
                variant: "destructive"
            });
        } finally {
            setIsSaving(false);
        }
    };

    if (!token || !isAuthenticated) {
        return <div className="p-4 text-center">Please log in to access settings</div>;
    }

    if (isLoading || !loadedSettings) {
        return (
            <div className="p-4 text-center flex items-center justify-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                Loading settings...
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <SettingsSection
                title="Base Airport"
                description="Set the primary airport for your schedule view"
            >
                <div className="grid gap-4 max-w-lg">
                    <div className="space-y-2">
                        <Label htmlFor="baseAirport">IATA Code</Label>
                        <div className="flex gap-2 items-start">
                            <div className="flex-1">
                                <Input
                                    id="baseAirport"
                                    value={loadedSettings.baseAirport}
                                    onChange={(e) => handleBaseAirportChange(e.target.value)}
                                    maxLength={3}
                                    className="uppercase"
                                    disabled={isSaving}
                                />
                                {baseAirportError && (
                                    <p className="text-sm text-red-500 mt-1">{baseAirportError}</p>
                                )}
                            </div>
                            <Button
                                onClick={handleBaseAirportSave}
                                disabled={isSaving || !!baseAirportError}
                                className="flex items-center gap-2"
                            >
                                {isSaving ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                ) : (
                                    <Plane className="h-4 w-4" />
                                )}
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </SettingsSection>

            <SettingsSection
                title="Date and Time Settings"
                description="Configure how dates and times are displayed throughout the application"
            >
                <div className="grid gap-4 max-w-lg">
                    <div className="space-y-2">
                        <Label htmlFor="timezone">Default Timezone</Label>
                        <Select
                            value={loadedSettings.timezone}
                            onValueChange={(value) => handleSettingChange('timezone', value)}
                            disabled={isSaving}
                        >
                            <SelectTrigger id="timezone">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="UTC">UTC</SelectItem>
                                <SelectItem value="America/New_York">Eastern Time</SelectItem>
                                <SelectItem value="America/Chicago">Central Time</SelectItem>
                                <SelectItem value="America/Denver">Mountain Time</SelectItem>
                                <SelectItem value="America/Los_Angeles">Pacific Time</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="dateFormat">Date Format</Label>
                        <Select
                            value={loadedSettings.dateFormat}
                            onValueChange={(value) => handleSettingChange('dateFormat', value)}
                            disabled={isSaving}
                        >
                            <SelectTrigger id="dateFormat">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="YYYY-MM-DD">YYYY-MM-DD</SelectItem>
                                <SelectItem value="MM/DD/YYYY">MM/DD/YYYY</SelectItem>
                                <SelectItem value="DD/MM/YYYY">DD/MM/YYYY</SelectItem>
                                <SelectItem value="MMM D, YYYY">MMM D, YYYY</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="timeFormat">Time Format</Label>
                        <Select
                            value={loadedSettings.timeFormat}
                            onValueChange={(value) => handleSettingChange('timeFormat', value)}
                            disabled={isSaving}
                        >
                            <SelectTrigger id="timeFormat">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="24">24-hour</SelectItem>
                                <SelectItem value="12">12-hour</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </SettingsSection>

            <SettingsSection
                title="Danger Zone"
                description="Destructive actions that cannot be undone"
            >
                <div className="grid gap-4 max-w-lg">
                    <div className="p-4 border border-red-200 rounded-lg bg-red-50">
                        <div className="flex items-center gap-2 mb-4">
                            <AlertTriangle className="h-5 w-5 text-red-600" />
                            <h3 className="font-medium text-red-900">Delete All Flights</h3>
                        </div>
                        <p className="text-sm text-red-700 mb-4">
                            Permanently remove all flights from the database. This action cannot be reversed.
                        </p>
                        <Button 
                            variant="destructive"
                            onClick={() => setShowDeleteDialog(true)}
                            className="flex items-center gap-2"
                        >
                            <Database className="h-4 w-4" />
                            Delete All Flights
                        </Button>
                    </div>
                </div>
            </SettingsSection>

            <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete all flights
                            from the database and remove all associated data.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={handleDeleteAllFlights}
                            className="bg-red-600 hover:bg-red-700"
                        >
                            Delete All Flights
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete all flights
                            from the database and remove all associated data.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={handleDeleteAllFlights}
                            className="bg-red-600 hover:bg-red-700"
                        >
                            Delete All Flights
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default AdminSettings;