import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Upload, FileSpreadsheet, AlertCircle, CheckCircle2 } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Progress } from "@/components/ui/progress";

interface SettingsSectionProps {
    title: string;
    description: string;
    children: React.ReactNode;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({ title, description, children }) => (
    <div className="space-y-4 pb-6 mb-6 border-b last:border-b-0">
        <div>
            <h2 className="text-lg font-semibold">{title}</h2>
            <p className="text-sm text-gray-500">{description}</p>
        </div>
        {children}
    </div>
);

const ExcelImport = () => {
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { token } = useAuth();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            setError('');
            setSuccess('');
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!file || !token) return;

        const formData = new FormData();
        formData.append('file', file);
        setIsUploading(true);
        setError('');
        setSuccess('');

        try {
            const response = await fetch('/api/FlightImport', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Import failed');
            }

            const data = await response.json();
            setSuccess(`Successfully imported ${data.importedCount} flights`);
            setFile(null);
            // Reset file input
            const fileInput = document.getElementById('excel-file') as HTMLInputElement;
            if (fileInput) fileInput.value = '';
        } catch (err) {
            setError('Error importing Excel file');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="space-y-6">
            <SettingsSection
                title="Excel File Import"
                description="Import flight data from Excel spreadsheets (.xlsx, .xls)"
            >
                <div className="max-w-lg">
                    {error && (
                        <Alert variant="destructive" className="mb-4">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {success && (
                        <Alert className="mb-4 border-green-200">
                            <CheckCircle2 className="h-4 w-4 text-green-600" />
                            <AlertDescription className="text-green-600">{success}</AlertDescription>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="excel-file">Select Excel File</Label>
                            <div className="grid w-full items-center gap-1.5">
                                <Input
                                    id="excel-file"
                                    type="file"
                                    accept=".xlsx,.xls"
                                    onChange={handleFileChange}
                                    required
                                    className="cursor-pointer"
                                />
                                <p className="text-sm text-muted-foreground">
                                    Supported formats: .xlsx, .xls
                                </p>
                            </div>
                        </div>

                        {isUploading && (
                            <div className="space-y-2">
                                <Progress value={33} className="w-full" />
                                <p className="text-sm text-muted-foreground text-center">
                                    Uploading file...
                                </p>
                            </div>
                        )}

                        <div className="flex justify-start">
                            <Button
                                type="submit"
                                disabled={!file || isUploading}
                                className="flex items-center gap-2"
                            >
                                <Upload className="h-4 w-4" />
                                {isUploading ? 'Importing...' : 'Import'}
                            </Button>
                        </div>
                    </form>
                </div>
            </SettingsSection>
        </div>
    );
};

export default ExcelImport;