// Layout.tsx
import React from 'react';
import Navigation from './Navigation';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className="flex min-h-screen bg-white">
            <Navigation />
            <div className="flex-1 lg:ml-0">
                <div className="lg:hidden h-14 bg-white shadow-sm flex items-center px-4">
                    <h1 className="text-xl font-bold">ScheduleView</h1>
                </div>
                <div className="p-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;