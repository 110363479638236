import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../services/api';
import { UIFlight, UIFlightCreateUpdate } from '../../services/types';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Pencil, Trash2, Plane, Plus, X } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';

const ManageFlights = () => {
    const [flights, setFlights] = useState<UIFlight[]>([]);
    const [selectedFlight, setSelectedFlight] = useState<UIFlight | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const { token } = useAuth();

    const [formData, setFormData] = useState<UIFlightCreateUpdate>({
        FlightNumber: '',
        AOC: '',
        AircraftType: '',
        Destination: '',
        Origin: '',
        DepartureTime: '',
        ArrivalTime: '',
        StartDate: '',
        EndDate: '',
        DaysOfWeek: '',
    });

    const loadFlights = useCallback(async () => {
        try {
            setIsLoading(true);
            if (!token) return;
            const today = new Date().toISOString().split('T')[0];
            const apiFlights = await api.flights.getAll(token, today);
            setFlights(apiFlights);
            setError('');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Error loading flights';
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    useEffect(() => {
        loadFlights();
    }, [loadFlights]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!token) return;

        try {
            if (selectedFlight) {
                await api.flights.update(token, selectedFlight.Id, {
                    ...formData,
                    Id: selectedFlight.Id
                });
            } else {
                await api.flights.create(token, formData);
            }
            await loadFlights();
            resetForm();
            setError('');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Error saving flight';
            setError(errorMessage);
        }
    };

    const handleDelete = async (id: number) => {
        if (!token || !window.confirm('Are you sure you want to delete this flight?')) return;

        try {
            await api.flights.delete(token, id);
            await loadFlights();
            setError('');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Error deleting flight';
            setError(errorMessage);
        }
    };

    const resetForm = () => {
        setSelectedFlight(null);
        setFormData({
            FlightNumber: '',
            AOC: '',
            AircraftType: '',
            Destination: '',
            Origin: '',
            DepartureTime: '',
            ArrivalTime: '',
            StartDate: '',
            EndDate: '',
            DaysOfWeek: '',
        });
    };

    if (isLoading) {
        return <div className="flex items-center justify-center p-6">Loading...</div>;
    }

    return (
        <div className="space-y-6">
            {error && (
                <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <Card>
                <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                        <Plane className="w-5 h-5" />
                        {selectedFlight ? 'Edit Flight' : 'Add New Flight'}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="flightNumber">Flight Number</Label>
                                <Input
                                    id="flightNumber"
                                    value={formData.FlightNumber}
                                    onChange={(e) => setFormData({ ...formData, FlightNumber: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="aoc">AOC</Label>
                                <Input
                                    id="aoc"
                                    value={formData.AOC}
                                    onChange={(e) => setFormData({ ...formData, AOC: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="aircraftType">Aircraft Type</Label>
                                <Input
                                    id="aircraftType"
                                    value={formData.AircraftType}
                                    onChange={(e) => setFormData({ ...formData, AircraftType: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="origin">Origin</Label>
                                <Input
                                    id="origin"
                                    value={formData.Origin}
                                    onChange={(e) => setFormData({ ...formData, Origin: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="destination">Destination</Label>
                                <Input
                                    id="destination"
                                    value={formData.Destination}
                                    onChange={(e) => setFormData({ ...formData, Destination: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="departureTime">Departure Time</Label>
                                <Input
                                    id="departureTime"
                                    type="time"
                                    value={formData.DepartureTime}
                                    onChange={(e) => setFormData({ ...formData, DepartureTime: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="arrivalTime">Arrival Time</Label>
                                <Input
                                    id="arrivalTime"
                                    type="time"
                                    value={formData.ArrivalTime}
                                    onChange={(e) => setFormData({ ...formData, ArrivalTime: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="startDate">Start Date</Label>
                                <Input
                                    id="startDate"
                                    type="date"
                                    value={formData.StartDate}
                                    onChange={(e) => setFormData({ ...formData, StartDate: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="endDate">End Date</Label>
                                <Input
                                    id="endDate"
                                    type="date"
                                    value={formData.EndDate}
                                    onChange={(e) => setFormData({ ...formData, EndDate: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="daysOfWeek">Days of Week</Label>
                                <Input
                                    id="daysOfWeek"
                                    placeholder="e.g., 1,2,3,4,5 for Mon-Fri"
                                    value={formData.DaysOfWeek}
                                    onChange={(e) => setFormData({ ...formData, DaysOfWeek: e.target.value })}
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex justify-end space-x-3">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={resetForm}
                            >
                                <X className="w-4 h-4 mr-2" />
                                Cancel
                            </Button>
                            <Button type="submit">
                                {selectedFlight ? (
                                    <>
                                        <Pencil className="w-4 h-4 mr-2" />
                                        Update
                                    </>
                                ) : (
                                    <>
                                        <Plus className="w-4 h-4 mr-2" />
                                        Add
                                    </>
                                )}
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>

            <Card>
                <CardContent className="p-0">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Flight Number</TableHead>
                                <TableHead>AOC</TableHead>
                                <TableHead>From/To</TableHead>
                                <TableHead>Date Range</TableHead>
                                <TableHead className="text-right">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {flights.map((flight) => (
                                <TableRow key={flight.Id}>
                                    <TableCell>{flight.FlightNumber}</TableCell>
                                    <TableCell>{flight.AOC}</TableCell>
                                    <TableCell>
                                        {flight.Origin} → {flight.Destination}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(flight.StartDate).toLocaleDateString()} -
                                        {new Date(flight.EndDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => setSelectedFlight(flight)}
                                            className="mr-2"
                                        >
                                            <Pencil className="w-4 h-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => handleDelete(flight.Id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            <Trash2 className="w-4 h-4" />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
};

export default ManageFlights;