// Navigation.tsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, Home, Plane, Shield, Info, LogIn, LogOut } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isAuthenticated, logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
        setIsOpen(false);
    };

    const getMenuItems = () => {
        const items = [
            {
                icon: <Home className="w-5 h-5" />,
                label: 'Home',
                href: '/home'
            },
            {
                icon: <Plane className="w-5 h-5" />,
                label: 'Flight Schedule',
                href: '/schedule'
            },
            {
                icon: <Info className="w-5 h-5" />,
                label: 'About',
                href: '/about'
            }
        ];

        if (isAuthenticated) {
            items.push(
                { icon: <Shield className="w-5 h-5" />, label: 'Admin Dashboard', href: '/admin' }
            );
        }

        if (!isAuthenticated) {
            items.push({ icon: <LogIn className="w-5 h-5" />, label: 'Login', href: '/login' });
        }

        return items;
    };

    return (
        <>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="lg:hidden fixed top-4 right-4 z-50 p-2 rounded-md bg-white shadow-lg"
            >
                {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>

            <aside className={`
                w-64 shrink-0 fixed top-0 left-0 h-screen bg-white shadow-lg z-40
                ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
                lg:relative lg:translate-x-0 transition-transform duration-300 ease-in-out
            `}>
                <div className="p-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-8">Menu</h2>
                    <nav>
                        <ul className="space-y-4">
                            {getMenuItems().map((item, index) => (
                                <li key={index}>
                                    <Link
                                        to={item.href}
                                        className={`flex items-center space-x-4 p-3 rounded-lg transition-colors ${location.pathname === item.href
                                                ? 'text-blue-600 bg-blue-50'
                                                : 'text-gray-600 hover:text-blue-600 hover:bg-blue-50'
                                            }`}
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        {item.icon}
                                        <span>{item.label}</span>
                                    </Link>
                                </li>
                            ))}
                            {isAuthenticated && (
                                <li>
                                    <button
                                        onClick={handleLogout}
                                        className="flex items-center space-x-4 text-gray-600 hover:text-blue-600 hover:bg-blue-50 p-3 rounded-lg transition-colors w-full"
                                    >
                                        <LogOut className="w-5 h-5" />
                                        <span>Logout</span>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    );
};

export default Navigation;