import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatDate(date: string | Date): string {
    if (!date) return '';
    try {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    } catch (err) {
        console.error('Date formatting error:', { input: date, error: err });
        return '';
    }
}

export function formatTime(time: string): string {
    if (!time) return '';
    try {
        const formattedTime = new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        return formattedTime;
    } catch (err) {
        console.error('Time formatting error:', { input: time, error: err });
        return '';
    }
}