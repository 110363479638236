import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout/Layout';
import FlightSchedule from './components/Flights/FlightSchedule';
import Navigation from './components/Layout/Navigation';
import LoginForm from './components/Auth/LoginForm';
import AdminDashboard from './components/Admin/AdminDashboard';
import About from './components/About/About';
import Home from './components/Home/Home';
import { useAuth } from './contexts/AuthContext';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
};

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        {/* Home als Standardroute */}
                        <Route path="/" element={<Navigate to="/home" replace />} />
                        <Route path="/home" element={<Home />} />

                        {/* Andere Routen */}
                        <Route path="/about" element={<About />} />
                        <Route path="/schedule" element={<FlightSchedule />} />
                        <Route path="/login" element={<LoginForm />} />
                        <Route
                            path="/admin"
                            element={
                                <ProtectedRoute>
                                    <AdminDashboard />
                                </ProtectedRoute>
                            }
                        />

                        {/* Catch all route jetzt zu /home statt / */}
                        <Route path="*" element={<Navigate to="/home" replace />} />
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
};

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}