import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Calendar, Clock, Plane, PlaneTakeoff, PlaneLanding } from 'lucide-react';
import { type UIFlight } from '../../services/types';
import { formatDate, formatTime } from '../../lib/utils';

interface FlightDetailsDialogProps {
    flight: UIFlight | null;
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

const AOCLogo: React.FC<{ aoc: string }> = ({ aoc }) => {
    const getLogoSrc = (aoc: string): string | null => {
        switch (aoc.toUpperCase()) {
            case 'FR':
                return '/images/logos/fr-logo.png';
            case 'RR':
                return '/images/logos/rr-logo.png';
            case 'LW':
                return '/images/logos/lw-logo.png';
            case 'AL':
                return '/images/logos/al-logo.png';
            default:
                return null;
        }
    };

    const logoSrc = getLogoSrc(aoc);

    if (!logoSrc) return <span className="text-base">{aoc}</span>;

    return (
        <div className="relative w-full">
            {/* Background logo with transparency */}
            <div className="absolute right-0 inset-y-0 opacity-5 flex items-center justify-end pr-4">
                <img
                    src={logoSrc}
                    alt=""
                    className="w-48 h-48 object-contain"
                />
            </div>
            {/* Foreground content */}
            <div className="relative flex items-center justify-between p-4">
                <span className="text-lg font-medium">{aoc}</span>
                <div className="min-w-[64px]">
                    <img
                        src={logoSrc}
                        alt={`${aoc} logo`}
                        className="w-20 h-20 object-contain"
                    />
                </div>
            </div>
        </div>
    );
};

const FlightDetailsDialog: React.FC<FlightDetailsDialogProps> = ({
    flight,
    open,
    onOpenChange
}) => {
    if (!flight) return null;

    const getDaysOfWeekText = (daysString: string | null | undefined): string => {
        if (!daysString) return 'No days specified';
        return daysString.split(',')
            .map(day => day.trim())
            .filter(day => day.length > 0)
            .join(', ');
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle className="flex items-center gap-2 text-xl">
                        <Plane className="h-6 w-6 text-blue-500" />
                        <span className="bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
                            Flight {flight.FlightNumber}
                        </span>
                    </DialogTitle>
                </DialogHeader>
                <div className="grid gap-6 py-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className="text-sm text-muted-foreground flex items-center gap-2">
                                <PlaneTakeoff className="h-4 w-4 text-blue-500" />
                                Origin
                            </label>
                            <p className="text-2xl font-semibold text-gray-900">{flight.Origin}</p>
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm text-muted-foreground flex items-center gap-2">
                                <PlaneLanding className="h-4 w-4 text-blue-500" />
                                Destination
                            </label>
                            <p className="text-2xl font-semibold text-gray-900">{flight.Destination}</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className="text-sm text-muted-foreground flex items-center gap-1">
                                <Clock className="h-4 w-4 text-blue-500" /> STD
                            </label>
                            <p className="text-lg font-semibold text-gray-900">{formatTime(flight.DepartureTime)}</p>
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm text-muted-foreground flex items-center gap-1">
                                <Clock className="h-4 w-4 text-blue-500" /> STA
                            </label>
                            <p className="text-lg font-semibold text-gray-900">{formatTime(flight.ArrivalTime)}</p>
                        </div>
                    </div>

                    <div className="space-y-3">
                        <label className="text-sm text-muted-foreground">Aircraft Details</label>
                        <div className="relative bg-gray-50/50 p-3 rounded-lg overflow-hidden">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <span className="text-sm text-muted-foreground col-span-1">Type:</span>
                                <span className="col-span-3 font-medium relative z-10">{flight.AircraftType}</span>
                                <span className="text-sm text-muted-foreground col-span-1">AOC:</span>
                                <div className="col-span-3">
                                    <AOCLogo aoc={flight.AOC} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-3">
                        <label className="text-sm text-muted-foreground flex items-center gap-1">
                            <Calendar className="h-4 w-4 text-blue-500" /> Schedule
                        </label>
                        <div className="space-y-3 bg-gray-50/50 p-3 rounded-lg">
                            <p className="flex justify-between">
                                <span className="text-sm text-muted-foreground">Valid From</span>
                                <span className="font-medium">{formatDate(flight.StartDate)}</span>
                            </p>
                            <p className="flex justify-between">
                                <span className="text-sm text-muted-foreground">Valid Until</span>
                                <span className="font-medium">{formatDate(flight.EndDate)}</span>
                            </p>
                            <div className="pt-2 border-t">
                                <span className="text-sm text-muted-foreground">Operating Days</span>
                                <p className="font-medium mt-1">{getDaysOfWeekText(flight.DaysOfWeek)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FlightDetailsDialog;