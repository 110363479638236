import React, { createContext, useContext, useState, useEffect } from 'react';
import { api } from '@/services/api';
import { Settings } from '@/services/types';

interface SettingsContextType {
    settings: Settings | null;
    updateSettings: (newSettings: Settings) => void;
    isLoading: boolean;
    error: string | null;
}

const SettingsContext = createContext<SettingsContextType>({
    settings: null,
    updateSettings: () => { },
    isLoading: true,
    error: null
});

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState<Settings | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchSettings = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('No authentication token found');
                return;
            }

            const response = await api.settings.getDateTime(token);
            setSettings(response);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch settings');
            console.error('Error fetching settings:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const updateSettings = async (newSettings: Settings) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('No authentication token found');
                return;
            }

            const response = await api.settings.updateDateTime(token, newSettings);
            setSettings(response);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to update settings');
            console.error('Error updating settings:', err);
            // Re-fetch settings in case of error to ensure consistency
            fetchSettings();
        }
    };

    return (
        <SettingsContext.Provider value={{ settings, updateSettings, isLoading, error }}>
            {children}
        </SettingsContext.Provider>
    );
};