import React from 'react';
import { Info, Shield, AlertTriangle, Mail, ExternalLink, Copyright, BadgeInfo } from 'lucide-react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
} from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";

const About = () => {
    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            {/* Header Section */}
            <div className="space-y-4 mb-8">
                <h1 className="text-4xl font-bold tracking-tight flex items-center gap-2">
                    <Info className="h-8 w-8 text-blue-500" />
                    <span className="bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
                        About ScheduleView
                    </span>
                </h1>
                <p className="text-muted-foreground text-lg">
                    A Simple flight schedule viewer developed in react.js
                </p>
                <Separator />
            </div>

            {/* Main Content */}
            <div className="grid gap-6">
                {/* Creator Info */}
                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                            <BadgeInfo className="h-5 w-5" />
                            Creator & Attribution
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <p>
                                Developed by Ruben X.
                            </p>
                            <p className="text-sm text-muted-foreground flex items-center gap-1">
                                <Copyright className="h-4 w-4" /> 2024. All rights reserved. Not available for use without prior authorization.
                            </p>
                        </div>
                        <div>
                            <Button
                                variant="outline"
                                size="sm"
                                className="gap-2"
                                asChild
                            >
                                <a href="mailto:tapioka.0-ehrfurcht@icloud.com">
                                    <Mail className="h-4 w-4" />
                                    Contact
                                </a>
                            </Button>
                        </div>
                    </CardContent>
                </Card>

                {/* Disclaimer */}
                <Card className="border-yellow-200 bg-yellow-50">
                    <CardHeader>
                        <CardTitle className="flex items-center gap-2 text-yellow-800">
                            <AlertTriangle className="h-5 w-5" />
                            Important Disclaimer
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4 text-yellow-800">
                        <p>
                            This application is not affiliated with Ryanair or any other airline.
                            All content is for informational purposes only and is not intended for public use.
                        </p>
                        <p>
                            Please note that the information provided may be incorrect or outdated.
                            Do not rely on this data for travel planning or official purposes.
                        </p>
                    </CardContent>
                </Card>

                {/* Technologies Used */}
                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                            <Shield className="h-5 w-5" />
                            Built With
                        </CardTitle>
                        <CardDescription>
                            Core components powering this application:
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <h3 className="font-medium">UI Components</h3>
                                <ul className="list-disc list-inside text-sm text-muted-foreground">
                                    <li>shadcn/ui - Beautiful and accessible components</li>
                                    <li>Lucide Icons - Simple and consistent icons</li>
                                    <li>Tailwind CSS - Utility-first CSS framework</li>
                                </ul>
                            </div>
                            <div className="space-y-2">
                                <h3 className="font-medium">Core Technologies</h3>
                                <ul className="list-disc list-inside text-sm text-muted-foreground">
                                    <li>React - Frontend library</li>
                                    <li>TypeScript - Type-safe JavaScript</li>
                                    <li>React Router - Navigation</li>
                                </ul>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Credits */}
                <Alert>
                    <AlertDescription className="flex items-center gap-2">
                        Special thanks to the creators of shadcn/ui and Lucide icons for their amazing work
                        <a
                            href="https://ui.shadcn.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-600"
                        >
                            Visit shadcn/ui
                            <ExternalLink className="h-3 w-3" />
                        </a>
                    </AlertDescription>
                </Alert>
            </div>
        </div>
    );
};

export default About;