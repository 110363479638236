import React, { useState, useEffect, useCallback } from 'react';
import { AlertCircle, ChevronDown, ChevronUp, CalendarDays, Plane, ArrowLeftRight, Building2, PlaneTakeoff, PlaneLanding, ArrowRight } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { UIFlight, FlightFilters } from '../../services/types';
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import FlightDetailsDialog from './FlightDetailsDialog';


type SortConfig = {
    key: keyof UIFlight | null;
    direction: 'asc' | 'desc';
};

type BaseOperatedPair = {
    outboundFlight: UIFlight;
    returnFlight: UIFlight;
};

const FlightSchedule = () => {
    const [allFlights, setAllFlights] = useState<UIFlight[]>([]);
    const [selectedFlight, setSelectedFlight] = useState<UIFlight | null>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

    const handleFlightClick = (flight: UIFlight) => {
        setSelectedFlight(flight);
        setDetailsDialogOpen(true);
    };
    const [filteredFlights, setFilteredFlights] = useState<UIFlight[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [baseLocation] = useState('ARN');
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        key: 'DepartureTime',
        direction: 'asc'
    });

    const [destinations, setDestinations] = useState<string[]>([]);
    const [origins, setOrigins] = useState<string[]>([]);

    const [filters, setFilters] = useState<FlightFilters>({
        destination: '',
        origin: '',
        onlyInbound: false,
        onlyOutbound: false,
        onlyBaseOperated: false,
    });

    const sortData = useCallback((data: UIFlight[]): UIFlight[] => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            const aValue = a[sortConfig.key as keyof UIFlight];
            const bValue = b[sortConfig.key as keyof UIFlight];

            if (aValue === null || bValue === null) return 0;

            let comparison = 0;
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                if (sortConfig.key === 'DepartureTime' || sortConfig.key === 'ArrivalTime') {
                    const timeA = new Date(`2000-01-01T${aValue}`).getTime();
                    const timeB = new Date(`2000-01-01T${bValue}`).getTime();
                    comparison = timeA - timeB;
                } else {
                    comparison = aValue.localeCompare(bValue);
                }
            } else {
                comparison = String(aValue).localeCompare(String(bValue));
            }

            return sortConfig.direction === 'asc' ? comparison : -comparison;
        });
    }, [sortConfig]);

    const findBaseOperatedFlights = useCallback((flights: UIFlight[]): UIFlight[] => {
        // Group flights by destination
        const flightsByDestination = flights.reduce((acc, flight) => {
            const destination = flight.Origin === baseLocation ? flight.Destination : flight.Origin;
            if (!acc[destination]) {
                acc[destination] = [];
            }
            acc[destination].push(flight);
            return acc;
        }, {} as Record<string, UIFlight[]>);

        const baseOperatedFlights: UIFlight[] = [];

        // Process each destination's flights
        Object.entries(flightsByDestination).forEach(([destination, destinationFlights]) => {
            if (destination === baseLocation) return; // Skip base location itself

            // Sort flights by departure time
            const sortedFlights = destinationFlights.sort((a, b) => {
                const timeA = new Date(`2000-01-01T${a.DepartureTime}`).getTime();
                const timeB = new Date(`2000-01-01T${b.DepartureTime}`).getTime();
                return timeA - timeB;
            });

            let currentPairStartTime: number | null = null;
            let isCurrentPairBaseOperated = false;

            // Process each flight chronologically
            for (let i = 0; i < sortedFlights.length; i++) {
                const flight = sortedFlights[i];
                const flightTime = new Date(`2000-01-01T${flight.DepartureTime}`).getTime();

                if (flight.Origin === baseLocation) {
                    // This is an outbound flight
                    if (currentPairStartTime === null) {
                        // Start of a potential base operated pair
                        currentPairStartTime = flightTime;
                        isCurrentPairBaseOperated = true;
                        baseOperatedFlights.push(flight);
                    }
                    // If there was an inbound first, this outbound is not base operated
                } else {
                    // This is an inbound flight
                    if (currentPairStartTime !== null && isCurrentPairBaseOperated && flightTime > currentPairStartTime) {
                        // This inbound completes a base operated pair
                        baseOperatedFlights.push(flight);
                    }
                    // Start a new sequence, not base operated
                    currentPairStartTime = flightTime;
                    isCurrentPairBaseOperated = false;
                }
            }
        });

        return baseOperatedFlights;
    }, [baseLocation]);


    const applyFilters = useCallback(() => {
        let filtered = [...allFlights];

        // First apply destination/origin filters
        if (filters.destination) {
            filtered = filtered.filter(flight => flight.Destination === filters.destination);
        }

        if (filters.origin) {
            filtered = filtered.filter(flight => flight.Origin === filters.origin);
        }

        // Apply base operated filter first before direction filters
        if (filters.onlyBaseOperated) {
            filtered = findBaseOperatedFlights(filtered);
        }

        // Then apply direction filters
        if (filters.onlyInbound) {
            filtered = filtered.filter(flight => flight.Destination === baseLocation);
        }

        if (filters.onlyOutbound) {
            filtered = filtered.filter(flight => flight.Origin === baseLocation);
        }

        // Finally sort the results
        filtered = sortData(filtered);
        setFilteredFlights(filtered);
    }, [allFlights, filters, sortData, findBaseOperatedFlights, baseLocation]);

    const loadFlights = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`/api/Flights?date=${selectedDate}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`API Error: ${response.status}`);
            }

            const responseData = await response.json() as UIFlight[];
            setAllFlights(responseData);

            const uniqueDestinations = Array.from(new Set(responseData.map(f => f.Destination)));
            const uniqueOrigins = Array.from(new Set(responseData.map(f => f.Origin)));

            setDestinations(uniqueDestinations);
            setOrigins(uniqueOrigins);
            setError('');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to load flights';
            setError(`Failed to load flights: ${errorMessage}`);
            console.error('Flight loading error:', err);
        } finally {
            setIsLoading(false);
        }
    }, [selectedDate]);

    const formatTime = useCallback((time: string): string => {
        if (!time) return '';
        try {
            const formattedTime = new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
            return formattedTime;
        } catch (err) {
            console.error('Time formatting error:', { input: time, error: err });
            return '';
        }
    }, []);

    const handleSort = useCallback((key: keyof UIFlight) => {
        setSortConfig(current => ({
            key,
            direction:
                current.key === key && current.direction === 'asc'
                    ? 'desc'
                    : 'asc',
        }));
    }, []);

    const handleDirectionFilter = useCallback((type: 'inbound' | 'outbound'): void => {
        setFilters(prev => ({
            ...prev,
            onlyInbound: type === 'inbound' ? !prev.onlyInbound : false,
            onlyOutbound: type === 'outbound' ? !prev.onlyOutbound : false,
        }));
    }, []);

    const SortHeader = useCallback(({ column, label }: { column: keyof UIFlight, label: string }) => {
        const isActive = sortConfig.key === column;
        return (
            <Button
                variant="ghost"
                size="default"
                className={`w-full justify-between px-6 py-3 text-xs font-medium uppercase tracking-wider ${isActive ? 'text-primary' : 'text-gray-500'
                    }`}
                onClick={() => handleSort(column)}
            >
                {label}
                {isActive && (
                    sortConfig.direction === 'asc'
                        ? <ChevronUp className="h-4 w-4 ml-2" />
                        : <ChevronDown className="h-4 w-4 ml-2" />
                )}
            </Button>
        );
    }, [sortConfig, handleSort]);

    const MobileSortSelect = () => (
        <Select
            value={`${sortConfig.key}_${sortConfig.direction}`}
            onValueChange={(value) => {
                const [key, direction] = value.split('_');
                setSortConfig({
                    key: key as keyof UIFlight,
                    direction: direction as 'asc' | 'desc'
                });
            }}
        >
            <SelectTrigger className="w-full mb-4">
                <SelectValue placeholder="Sort by..." />
            </SelectTrigger>
            <SelectContent>
                <SelectItem value="FlightNumber_asc">Flight Number (A-Z)</SelectItem>
                <SelectItem value="FlightNumber_desc">Flight Number (Z-A)</SelectItem>
                <SelectItem value="DepartureTime_asc">Departure Time (Early→Late)</SelectItem>
                <SelectItem value="DepartureTime_desc">Departure Time (Late→Early)</SelectItem>
                <SelectItem value="ArrivalTime_asc">Arrival Time (Early→Late)</SelectItem>
                <SelectItem value="ArrivalTime_desc">Arrival Time (Late→Early)</SelectItem>
            </SelectContent>
        </Select>
    );

    const FlightCard = ({ flight }: { flight: UIFlight }) => (
        <div
            className="p-4 border rounded-lg mb-3 cursor-pointer hover:bg-muted/50 shadow-sm"
            onClick={() => handleFlightClick(flight)}
        >
            <div className="mb-4">
                <div className="flex justify-between items-center">
                    <span className="font-medium text-base">{flight.FlightNumber}</span>
                    <span className="text-sm text-muted-foreground">{flight.AOC}</span>
                </div>
            </div>

            <div className="flex items-start justify-between">
                <div className="flex-1">
                    <div className="text-sm font-medium">{flight.Origin}</div>
                    <div className="text-base font-medium mt-1">{formatTime(flight.DepartureTime)}</div>
                </div>

                <div className="flex-none px-4 pt-2">
                    <ArrowRight className="h-4 w-4 text-muted-foreground" />
                </div>

                <div className="flex-1 text-right">
                    <div className="text-sm font-medium">{flight.Destination}</div>
                    <div className="text-base font-medium mt-1">{formatTime(flight.ArrivalTime)}</div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        loadFlights();
    }, [loadFlights]);

    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    return (
        <>
            {error && (
                <Alert variant="destructive" className="mb-6">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <Card>
            <CardHeader>
                <div className="flex items-center gap-2">
                    <Plane className="h-8 w-8 text-blue-500" />
                    <CardTitle className="text-2xl">
                        <span className="bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
                            Flight Schedule
                        </span>
                    </CardTitle>
                </div>
            </CardHeader>
            <CardContent>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
                        <div className="space-y-2">
                            <Label htmlFor="date" className="flex items-center gap-2">
                                <CalendarDays className="h-4 w-4" />
                                Date
                            </Label>
                            <Input
                                id="date"
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="origin" className="flex items-center gap-2">
                                <PlaneTakeoff className="h-4 w-4" />
                                Origin
                            </Label>
                            <Select
                                value={filters.origin || 'all'}
                                onValueChange={(value) => setFilters(prev => ({
                                    ...prev,
                                    origin: value === 'all' ? '' : value
                                }))}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="All Origins" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All Origins</SelectItem>
                                    {origins.map(origin => (
                                        <SelectItem key={origin} value={origin}>{origin}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="destination" className="flex items-center gap-2">
                                <PlaneLanding className="h-4 w-4" />
                                Destination
                            </Label>
                            <Select
                                value={filters.destination}
                                onValueChange={(value) => setFilters(prev => ({ ...prev, destination: value === 'all' ? '' : value }))}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="All Destinations" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All Destinations</SelectItem>
                                    {destinations.map(dest => (
                                        <SelectItem key={dest} value={dest}>{dest}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className="space-y-4">
                            <Label className="flex items-center gap-2">
                                <ArrowLeftRight className="h-4 w-4" />
                                Direction
                            </Label>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center space-x-2">
                                    <Checkbox
                                        id="inbound"
                                        checked={filters.onlyInbound}
                                        onCheckedChange={() => handleDirectionFilter('inbound')}
                                    />
                                    <label htmlFor="inbound">Inbound Only</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Checkbox
                                        id="outbound"
                                        checked={filters.onlyOutbound}
                                        onCheckedChange={() => handleDirectionFilter('outbound')}
                                    />
                                    <label htmlFor="outbound">Outbound Only</label>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <Label className="flex items-center gap-2">
                                <Building2 className="h-4 w-4" />
                                Operator
                            </Label>
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="baseOperated"
                                    checked={filters.onlyBaseOperated}
                                    onCheckedChange={(checked) =>
                                        setFilters(prev => ({
                                            ...prev,
                                            onlyBaseOperated: checked as boolean
                                        }))
                                    }
                                />
                                <label htmlFor="baseOperated">Base Operated Only</label>
                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <div className="flex justify-center items-center h-32">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                        </div>
                    ) : (
                        <>
                            {/* Mobile view */}
                                <div className="lg:hidden">
                                    <MobileSortSelect />
                                    {filteredFlights.map((flight: UIFlight) => (
                                        <FlightCard key={flight.Id} flight={flight} />
                                    ))}
                                </div>

                            {/* Desktop view */}
                            <div className="hidden lg:block w-full overflow-x-auto">
                                <div className="min-w-full inline-block align-middle">
                                    <div className="overflow-hidden">
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="px-0">
                                                        <SortHeader column="FlightNumber" label="Flight Number" />
                                                    </TableHead>
                                                    <TableHead className="px-0">
                                                        <SortHeader column="Origin" label="Origin" />
                                                    </TableHead>
                                                    <TableHead className="px-0">
                                                        <SortHeader column="DepartureTime" label="STD" />
                                                    </TableHead>
                                                    <TableHead className="px-0">
                                                        <SortHeader column="Destination" label="Destination" />
                                                    </TableHead>
                                                    <TableHead className="px-0">
                                                        <SortHeader column="ArrivalTime" label="STA" />
                                                    </TableHead>
                                                    <TableHead className="px-0 hidden md:table-cell">
                                                        <SortHeader column="AOC" label="AOC" />
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {filteredFlights.map((flight: UIFlight) => (
                                                    <TableRow
                                                        key={flight.Id}
                                                        className="cursor-pointer hover:bg-muted/50"
                                                        onClick={() => handleFlightClick(flight)}
                                                    >
                                                        <TableCell className="font-medium">{flight.FlightNumber}</TableCell>
                                                        <TableCell>{flight.Origin}</TableCell>
                                                        <TableCell>{formatTime(flight.DepartureTime)}</TableCell>
                                                        <TableCell>{flight.Destination}</TableCell>
                                                        <TableCell>{formatTime(flight.ArrivalTime)}</TableCell>
                                                        <TableCell className="hidden md:table-cell">{flight.AOC}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>

            <FlightDetailsDialog
                flight={selectedFlight}
                open={detailsDialogOpen}
                onOpenChange={setDetailsDialogOpen}
            />
        </>
    );
};

export default FlightSchedule;