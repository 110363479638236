import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Activity, Smartphone, Chrome } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../services/api';

// Simplified types to match backend response
interface Stats {
    totalVisits: number;
    deviceStats: Record<string, number>;
    browserStats: Record<string, number>;
    topPaths: Array<{
        path: string;
        count: number;
    }>;
}

const Stats = () => {
    const [stats, setStats] = useState<Stats | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchStats = async () => {
            if (!token) return; // Exit early if no token

            try {
                setLoading(true);
                const data = await api.analytics.getStats(token);
                setStats(data);
                setError(null);
            } catch (err) {
                const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
                console.error('Failed to fetch stats:', errorMessage);
                setError(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchStats();
            const interval = setInterval(fetchStats, 300000);
            return () => clearInterval(interval);
        } else {
            setLoading(false);
            setError('Authentication required');
        }
    }, [token]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
            </div>
        );
    }

    if (!token) {
        return <div className="text-gray-500">Please log in to view statistics</div>;
    }

    if (error) {
        return (
            <div className="text-red-500 p-4 bg-red-50 rounded-lg">
                Error loading statistics: {error}
            </div>
        );
    }

    if (!stats) {
        return <div className="text-gray-500">No statistics available</div>;
    }

    return (
        <div className="space-y-6">
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Card>
                    <CardContent className="pt-6">
                        <div className="flex items-center space-x-4">
                            <Activity className="h-8 w-8 text-blue-500" />
                            <div>
                                <p className="text-sm font-medium text-gray-500">Total Visits</p>
                                <h3 className="text-2xl font-bold">{stats.totalVisits.toLocaleString()}</h3>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="pt-6">
                        <div className="flex items-center space-x-4">
                            <Smartphone className="h-8 w-8 text-green-500" />
                            <div>
                                <p className="text-sm font-medium text-gray-500">Devices</p>
                                <h3 className="text-2xl font-bold">
                                    {Object.entries(stats.deviceStats)
                                        .sort(([, a], [, b]) => b - a)[0]?.[0] || 'N/A'}
                                </h3>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent className="pt-6">
                        <div className="flex items-center space-x-4">
                            <Chrome className="h-8 w-8 text-purple-500" />
                            <div>
                                <p className="text-sm font-medium text-gray-500">Browsers</p>
                                <h3 className="text-2xl font-bold">
                                    {Object.entries(stats.browserStats)
                                        .sort(([, a], [, b]) => b - a)[0]?.[0] || 'N/A'}
                                </h3>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* Most Visited Pages */}
            <Card>
                <CardHeader>
                    <CardTitle>Most Visited Pages</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        {stats.topPaths.map((pathStat, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <div className="text-sm font-medium">{pathStat.path}</div>
                                <div className="text-sm text-gray-500">{pathStat.count} visits</div>
                            </div>
                        ))}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Stats;