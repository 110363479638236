//api.ts
import {
    APIFlight,
    FlightFilters,
    LoginCredentials,
    LoginResponse,
    Settings,
    PageViewRequest,
} from './types';

const getBaseUrl = () => {
    // In both development and production, we can just use relative URLs
    // Development: proxy in package.json handles it
    // Production: nginx handles it
    return '/api';
};

//DEBUG 
export const debugApi = {
    testConnection: async (token: string) => {
        try {
            const response = await fetch(`${getBaseUrl()}/analytics/debug`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('API test failed:', error);
            throw error;
        }
    }
};
//END OF DEBUG

// Create a reusable function for handling response errors
const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        let errorMessage = `Request failed with status ${response.status}`;

        try {
            // Try to parse error as JSON
            const errorJson = JSON.parse(errorText);
            errorMessage = errorJson.message || errorJson.error || errorText;
        } catch {
            errorMessage = errorText;
        }

        // Handle specific status codes
        switch (response.status) {
            case 401:
                // Clear token and redirect to login
                localStorage.removeItem('authToken');
                window.location.href = '/login';
                throw new Error('Session expired. Please login again.');
            case 403:
                throw new Error('You do not have permission to perform this action.');
            default:
                throw new Error(errorMessage);
        }
    }
    return response;
};

// Create a function to get headers with auth token
const getAuthHeaders = (token: string, isJson: boolean = true) => {
    const headers: Record<string, string> = {
        'Authorization': `Bearer ${token}`
    };

    if (isJson) {
        headers['Content-Type'] = 'application/json';
    }

    return headers;
};

export const api = {
    auth: {
        login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
            try {
                const response = await fetch(`${getBaseUrl()}/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                });

                await handleResponse(response);
                const data = await response.json();

                if (!data.token) {
                    throw new Error('No token in response');
                }

                return data;
            } catch (error) {
                console.error('Login error:', error);
                throw error;
            }
        },

        validateToken: async (token: string): Promise<boolean> => {
            try {
                const response = await fetch(`${getBaseUrl()}/auth/validate`, {
                    headers: getAuthHeaders(token)
                });
                return response.ok;
            } catch {
                return false;
            }
        }
    },

    flights: {
        getAll: async (token: string, date?: string, filters?: FlightFilters): Promise<APIFlight[]> => {
            const queryParams = new URLSearchParams();
            if (date) {
                queryParams.append('date', date);
            }

            if (filters) {
                Object.entries(filters).forEach(([key, value]) => {
                    if (value !== undefined && value !== '') {
                        queryParams.append(key, String(value));
                    }
                });
            }

            const url = `${getBaseUrl()}/flights${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

            const response = await fetch(url, {
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
            return response.json();
        },

        create: async (token: string, flight: Omit<APIFlight, 'Id'>): Promise<APIFlight> => {
            const response = await fetch(`${getBaseUrl()}/flights`, {
                method: 'POST',
                headers: getAuthHeaders(token),
                body: JSON.stringify(flight)
            });

            await handleResponse(response);
            return response.json();
        },

        update: async (token: string, id: number, flight: APIFlight): Promise<APIFlight> => {
            const response = await fetch(`${getBaseUrl()}/flights/${id}`, {
                method: 'PUT',
                headers: getAuthHeaders(token),
                body: JSON.stringify(flight)
            });

            await handleResponse(response);
            return response.json();
        },

        delete: async (token: string, id: number): Promise<void> => {
            const response = await fetch(`${getBaseUrl()}/flights/${id}`, {
                method: 'DELETE',
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
        },

        deleteAll: async (token: string): Promise<void> => {
            const response = await fetch(`${getBaseUrl()}/flights/all`, {
                method: 'DELETE',
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
        },

        export: async (token: string, format: 'json' | 'csv'): Promise<Blob> => {
            const response = await fetch(`${getBaseUrl()}/flights/export?format=${format}`, {
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
            return response.blob();
        }
    },

    settings: {
        getDateTime: async (token: string): Promise<Settings> => {
            const response = await fetch(`${getBaseUrl()}/settings`, {
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
            return response.json();
        },
        updateDateTime: async (token: string, settings: Settings): Promise<Settings> => {
            // Only transform baseAirport if it's being updated
            const settingsToUpdate = {
                ...settings,
                // Only call toUpperCase() if baseAirport exists in the settings
                baseAirport: settings.baseAirport ? settings.baseAirport.toUpperCase() : settings.baseAirport
            };

            const response = await fetch(`${getBaseUrl()}/settings`, {
                method: 'PUT',
                headers: getAuthHeaders(token),
                body: JSON.stringify(settingsToUpdate)
            });

            await handleResponse(response);
            return response.json();
        }
    },

    analytics: {
        trackPage: async (request: PageViewRequest): Promise<void> => {
            try {
                console.log('Making analytics request:', {
                    url: `${getBaseUrl()}/analytics/track-page`,
                    method: 'POST',
                    body: request
                });

                const response = await fetch(`${getBaseUrl()}/analytics/track-page`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                });

                console.log('Analytics response:', {
                    status: response.status,
                    ok: response.ok
                });

                await handleResponse(response);
            } catch (error) {
                console.error('Track page error:', error);
                throw error;
            }
        },

        getStats: async (token: string) => {
            const response = await fetch(`${getBaseUrl()}/analytics/stats`, {
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
            return response.json();
        },
    },

    maintenance: {
        optimizeDatabase: async (token: string): Promise<void> => {
            const response = await fetch(`${getBaseUrl()}/maintenance/optimize`, {
                method: 'POST',
                headers: getAuthHeaders(token)
            });

            await handleResponse(response);
        }
    }
};