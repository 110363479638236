import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import ExcelImport from './ExcelImport';
import ManageFlights from './ManageFlights';
import Stats from './Stats';
import AdminSettings from './AdminSettings';
import { Plane, FileSpreadsheet, LayoutDashboard, Settings, Activity } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState<'flights' | 'import' | 'settings' | 'stats'>('flights');
    const { token } = useAuth();
    const navigate = useNavigate();

    if (!token) {
        navigate('/login');
        return null;
    }

    return (
        <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
                <LayoutDashboard className="h-8 w-8 text-blue-600" />
                <h1 className="text-2xl font-bold">Admin Dashboard</h1>
            </div>
            <Card>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8 px-6">
                        <button
                            className={`${activeTab === 'flights'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm inline-flex items-center gap-2`}
                            onClick={() => setActiveTab('flights')}
                        >
                            <Plane className="h-4 w-4" />
                            Manage Flights
                        </button>
                        <button
                            className={`${activeTab === 'import'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm inline-flex items-center gap-2`}
                            onClick={() => setActiveTab('import')}
                        >
                            <FileSpreadsheet className="h-4 w-4" />
                            Excel Import
                        </button>
                        <button
                            className={`${activeTab === 'stats'
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm inline-flex items-center gap-2`}
                            onClick={() => setActiveTab('stats')}
                        >
                            <Activity className="h-4 w-4" />
                            Stats
                        </button>
                        <button
                            className={`${activeTab === 'settings'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm inline-flex items-center gap-2`}
                            onClick={() => setActiveTab('settings')}
                        >
                            <Settings className="h-4 w-4" />
                            Settings
                        </button>
                    </nav>
                </div>
                <CardContent className="pt-6">
                    {activeTab === 'flights' ? (
                        <ManageFlights />
                    ) : activeTab === 'import' ? (
                        <ExcelImport />
                    ) : activeTab === 'stats' ? (
                        <Stats />
                    ) : (
                        <AdminSettings />
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default AdminDashboard;