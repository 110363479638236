"use client"
import {
    Area,
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { ElementType, ReactElement, ComponentProps } from "react"
import { cn } from "@/lib/utils"

export interface ChartConfig {
    [key: string]: {
        label: string
        color: string
        icon?: ElementType
    }
}

interface ChartContainerProps {
    config: ChartConfig
    children: ReactElement
}

export function ChartContainer({ config, children }: ChartContainerProps) {
    return (
        <>
            <style>{`
                :root {
                    ${Object.entries(config)
                    .map(
                        ([key, value]) => `
                        --color-${key.toLowerCase()}: ${value.color};
                        `
                    )
                    .join("")}
                }
            `}</style>
            <ResponsiveContainer width="100%" height={300}>
                {children}
            </ResponsiveContainer>
        </>
    )
}

interface LegendContentProps {
    payload?: Array<{
        value: string
        color: string
        dataKey: string
    }>
    chartConfig?: ChartConfig
}

export function ChartLegendContent({ payload, chartConfig }: LegendContentProps) {
    if (!payload) return null
    return (
        <div className="flex gap-4">
            {payload.map((entry, index) => {
                const { color, value, dataKey } = entry
                const Icon = chartConfig?.[dataKey]?.icon

                return (
                    <div key={`item-${index}`} className="flex items-center gap-2">
                        <span
                            className="h-3 w-3 rounded-full"
                            style={{ backgroundColor: color }}
                        />
                        <span className="text-sm font-medium">
                            {value}
                            {Icon && <Icon className="ml-1 h-3 w-3 inline" />}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

interface TooltipContentProps {
    active?: boolean
    payload?: any[]
    label?: string
    className?: string
    config?: ChartConfig
    formatter?: (value: number) => string | number
    indicator?: "line" | "dot"
}

export function ChartTooltipContent({
    active,
    payload,
    label,
    className,
    config,
    formatter,
    indicator = "dot"
}: TooltipContentProps) {
    if (!active || !payload) return null
    return (
        <div
            className={cn(
                "rounded-lg border bg-background p-2 shadow-sm",
                className
            )}
        >
            <div className="grid gap-2">
                <div className="grid gap-1">
                    <div className="text-xs text-muted-foreground">{label}</div>
                    <div className="grid gap-1">
                        {payload.map((value: any, index: number) => {
                            const formattedValue = formatter
                                ? formatter(value.value)
                                : value.value
                            return (
                                <div key={index} className="flex items-center gap-2">
                                    <span
                                        className={cn(
                                            indicator === "line" ? "h-0.5 w-3" : "h-3 w-3 rounded-full"
                                        )}
                                        style={{ backgroundColor: value.color ?? value.stroke }}
                                    />
                                    <span className="text-sm font-medium">
                                        {value.dataKey}: {formattedValue}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ChartLegend = Legend

export function ChartTooltip(props: Partial<ComponentProps<typeof Tooltip>>) {
    return <Tooltip {...props} />
}

export function ChartGrid(props: Partial<ComponentProps<typeof CartesianGrid>>) {
    return <CartesianGrid {...props} />
}

// Export all necessary chart components
export {
    Area,
    Line,
    Bar,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid
}