import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
    Plane,
    Calendar,
    ArrowRight,
    ArrowLeft,
    Bell,
    Shield,
    ArrowDownRight,
    ArrowUpRight,
    ExternalLink,
    LayoutDashboard,
    TrendingUp
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import {
    Card,
    CardHeader,
    CardTitle,
    CardDescription,
    CardContent,
    CardFooter
} from "@/components/ui/card";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog";
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip, ChartTooltipContent, ChartLegendContent } from "@/components/ui/chart";
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { api } from '../../services/api';
import { APIFlight, TimeSlot, MonthlyData } from '../../services/types';

interface FlightStats {
    activeFlights: number;
    todayFlights: number;
    remainingInbound: number;
    remainingOutbound: number;
}

interface FlightModalProps {
    isOpen: boolean;
    onClose: () => void;
    flights: APIFlight[];
    title: string;
    description: string;
}

const formatTime = (timeStr: string) => {
    return timeStr.split(':').slice(0, 2).join(':');
};

const isAfterMidnight = (time: string) => {
    const [hours] = time.split(':').map(Number);
    return hours >= 0 && hours < 6; // Consider flights until 6 AM as previous day's flights
};

const FlightModal: React.FC<FlightModalProps> = ({ isOpen, onClose, flights, title, description }) => (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
                <DialogTitle className="flex items-center gap-2 text-xl">
                    <span className="bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
                        {title}
                    </span>
                </DialogTitle>
                <DialogDescription className="text-muted-foreground">
                    {description}
                </DialogDescription>
            </DialogHeader>
            <div className="space-y-4 mt-4">
                {flights.length === 0 ? (
                    <div className="text-center py-8">
                        <p className="text-muted-foreground">No flights found.</p>
                    </div>
                ) : (
                    <div className="divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white">
                        {flights.map((flight, index) => {
                            const isActiveFlightsView = title === 'Active Flights';

                            return (
                                <div
                                    key={index}
                                    className={`p-4 transition-colors ${isActiveFlightsView ? 'hover:bg-blue-50/50 cursor-pointer' : ''}`}
                                    onClick={isActiveFlightsView ? () => window.open(`http://fr24.com/${flight.FlightNumber}`, '_blank') : undefined}
                                >
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <p className="font-medium flex items-center gap-2 text-gray-900">
                                                {flight.Origin} → {flight.Destination}
                                                {isActiveFlightsView && (
                                                    <ExternalLink className="h-4 w-4 text-blue-500" />
                                                )}
                                            </p>
                                            <p className="text-sm text-muted-foreground mt-1">
                                                Flight {flight.FlightNumber}
                                            </p>
                                        </div>
                                        <div className="text-right">
                                            <p className="font-medium text-gray-900">
                                                {formatTime(flight.DepartureTime)} - {formatTime(flight.ArrivalTime)}
                                            </p>
                                            <p className="text-sm text-muted-foreground mt-1">
                                                {flight.AOC}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </DialogContent>
    </Dialog>
);

const Home = () => {
    const { isAuthenticated, token } = useAuth();
    const navigate = useNavigate();
    const [flights, setFlights] = useState<APIFlight[]>([]);
    const [monthlyFlights, setMonthlyFlights] = useState<MonthlyData[]>([]);
    const [monthlyLoading, setMonthlyLoading] = useState(true);
    const [filteredFlights, setFilteredFlights] = useState<APIFlight[]>([]);
    const [allFlights, setAllFlights] = useState<APIFlight[]>([]);
    const [flightStats, setFlightStats] = useState<FlightStats>({
        activeFlights: 0,
        todayFlights: 0,
        remainingInbound: 0,
        remainingOutbound: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [modalState, setModalState] = useState({
        isOpen: false,
        flights: [] as APIFlight[],
        title: '',
        description: ''
    });
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [showBaseOperated, setShowBaseOperated] = useState(false);

    const timeToMinutes = (timeStr: string) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const isOvernightFlight = (depTime: string, arrTime: string) => {
        const depMinutes = timeToMinutes(depTime);
        const arrMinutes = timeToMinutes(arrTime);
        return arrMinutes < depMinutes;
    };

    const calculateTotalFlights = useCallback((data: TimeSlot[]) => {
        return data.reduce((acc, slot) => acc + slot.inbound + slot.outbound, 0);
    }, []);

    const dailyChartConfig = {
        inbound: {
            label: "Inbound Flights",
            color: "hsl(var(--chart-1))",
        },
        outbound: {
            label: "Outbound Flights",
            color: "hsl(var(--chart-2))",
        }
    } satisfies ChartConfig;

    const findBaseOperatedFlights = useCallback((flightsData: APIFlight[]) => {
        const baseLocation = 'ARN';
        const flightsByDestination = flightsData.reduce((acc, flight) => {
            const destination = flight.Origin === baseLocation ? flight.Destination : flight.Origin;
            if (!acc[destination]) {
                acc[destination] = [];
            }
            acc[destination].push(flight);
            return acc;
        }, {} as Record<string, APIFlight[]>);

        const baseOperatedFlights: APIFlight[] = [];

        Object.entries(flightsByDestination).forEach(([destination, destinationFlights]) => {
            if (destination === baseLocation) return;

            const sortedFlights = destinationFlights.sort((a, b) => {
                const timeA = new Date(`2000-01-01T${a.DepartureTime}`).getTime();
                const timeB = new Date(`2000-01-01T${b.DepartureTime}`).getTime();
                return timeA - timeB;
            });

            let currentPairStartTime: number | null = null;
            let isCurrentPairBaseOperated = false;

            for (let i = 0; i < sortedFlights.length; i++) {
                const flight = sortedFlights[i];
                const flightTime = new Date(`2000-01-01T${flight.DepartureTime}`).getTime();

                if (flight.Origin === baseLocation) {
                    if (currentPairStartTime === null) {
                        currentPairStartTime = flightTime;
                        isCurrentPairBaseOperated = true;
                        baseOperatedFlights.push(flight);
                    }
                } else {
                    if (currentPairStartTime !== null && isCurrentPairBaseOperated && flightTime > currentPairStartTime) {
                        baseOperatedFlights.push(flight);
                    }
                    currentPairStartTime = flightTime;
                    isCurrentPairBaseOperated = false;
                }
            }
        });

        return baseOperatedFlights;
    }, []);

    const prepareDailyDistribution = useCallback((flightsData: APIFlight[]): TimeSlot[] => {
        const timeSlots = Array.from({ length: 24 }, (_, i) => ({
            hour: i,
            inbound: 0,
            outbound: 0,
            time: `${String(i).padStart(2, '0')}:00`
        }));

        const relevantFlights = showBaseOperated ? findBaseOperatedFlights(flightsData) : flightsData;

        relevantFlights.forEach((flight: APIFlight) => {
            const hour = parseInt(flight.DepartureTime.split(':')[0]);
            if (flight.Origin === 'ARN') {
                timeSlots[hour].outbound++;
            }
            if (flight.Destination === 'ARN') {
                timeSlots[hour].inbound++;
            }
        });

        return timeSlots;
    }, [showBaseOperated, findBaseOperatedFlights]);

    const prepareMonthlyData = useCallback((flightsData: APIFlight[]): MonthlyData[] => {
        const daysInMonth = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            0
        ).getDate();

        // Initialize array for all days in month
        const monthData = Array.from({ length: daysInMonth }, (_, i) => ({
            day: i,  // Changed from i + 1 to i to make it zero-based
            flights: 0
        }));

        const relevantFlights = showBaseOperated ? findBaseOperatedFlights(flightsData) : flightsData;

        // Aggregate flights by day
        relevantFlights.forEach((flight: APIFlight) => {
            const day = parseInt(flight.StartDate.split('-')[2]);
            if (day > 0 && day <= daysInMonth) {
                monthData[day - 1].flights++;
            }
        });

        return monthData;
    }, [selectedMonth, showBaseOperated, findBaseOperatedFlights]);

    const navigateMonth = (direction: number) => {
        setSelectedMonth(prev => {
            const newDate = new Date(prev);
            newDate.setMonth(prev.getMonth() + direction);
            return newDate;
        });
    };

    const handleStatClick = (type: string) => {
        const now = new Date();
        const currentTimeUTC = now.getUTCHours() * 60 + now.getUTCMinutes();

        let filteredFlights: APIFlight[] = [];
        let title = '';
        let description = '';

        switch (type) {
            case 'active':
                filteredFlights = flights.filter(flight => {
                    const depMinutes = timeToMinutes(flight.DepartureTime);
                    const arrMinutes = timeToMinutes(flight.ArrivalTime);
                    if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime)) {
                        return currentTimeUTC >= depMinutes || currentTimeUTC <= arrMinutes;
                    }
                    return currentTimeUTC >= depMinutes && currentTimeUTC <= arrMinutes;
                });
                title = 'Active Flights';
                description = 'Flights currently in the air';
                break;
            case 'today':
                filteredFlights = [...flights].sort((a, b) => {
                    const timeA = timeToMinutes(a.DepartureTime);
                    const timeB = timeToMinutes(b.DepartureTime);
                    return timeA - timeB;
                });
                title = "Today's Flights";
                description = 'All scheduled flights for today';
                break;
            case 'inbound':
                filteredFlights = flights.filter(flight => {
                    if (flight.Destination !== 'ARN') return false;

                    const depMinutes = timeToMinutes(flight.DepartureTime);
                    const arrMinutes = timeToMinutes(flight.ArrivalTime);

                    // Include overnight flights arriving after midnight
                    if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime) &&
                        isAfterMidnight(flight.ArrivalTime)) {
                        return true;
                    }

                    // Include flights that haven't arrived yet
                    if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime)) {
                        return currentTimeUTC >= depMinutes || currentTimeUTC <= arrMinutes;
                    }

                    return currentTimeUTC <= arrMinutes;
                });
                title = 'Remaining Inbound Flights';
                description = 'Flights still arriving today';
                break;
            case 'outbound':
                filteredFlights = flights.filter(flight => {
                    const depMinutes = timeToMinutes(flight.DepartureTime);
                    return depMinutes > currentTimeUTC && flight.Origin === 'ARN';
                });
                title = 'Remaining Outbound Flights';
                description = 'Flights still departing today';
                break;
        }

        setModalState({
            isOpen: true,
            flights: filteredFlights,
            title,
            description
        });
    };

    useEffect(() => {
        const fetchMonthlyFlights = async () => {
            try {
                setMonthlyLoading(true);
                // Calculate start and end dates for the selected month
                const startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
                const endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);

                const monthData: MonthlyData[] = [];
                let currentDate = new Date(startDate);

                while (currentDate <= endDate) {
                    const dateStr = currentDate.toISOString().split('T')[0];
                    const currentToken = token || 'temp';
                    const dailyFlights = await api.flights.getAll(currentToken, dateStr);

                    const relevantFlights = showBaseOperated ?
                        findBaseOperatedFlights(dailyFlights) :
                        dailyFlights;

                    monthData.push({
                        day: currentDate.getDate(),
                        flights: relevantFlights.length
                    });

                    currentDate.setDate(currentDate.getDate() + 1);
                }

                setMonthlyFlights(monthData);
            } catch (err) {
                setError('Failed to fetch monthly flight statistics');
            } finally {
                setMonthlyLoading(false);
            }
        };

        fetchMonthlyFlights();
    }, [selectedMonth, token, showBaseOperated, findBaseOperatedFlights]);

    useEffect(() => {
        const fetchFlightStats = async () => {
            try {
                const currentDate = new Date().toISOString().split('T')[0];
                const currentToken = token || 'temp';
                const fetchedFlights = await api.flights.getAll(currentToken, currentDate);
                setFlights(fetchedFlights);

                const now = new Date();
                const currentTimeUTC = now.getUTCHours() * 60 + now.getUTCMinutes();

                const activeFlights = fetchedFlights.filter(flight => {
                    const depMinutes = timeToMinutes(flight.DepartureTime);
                    const arrMinutes = timeToMinutes(flight.ArrivalTime);

                    // Include overnight flights that are still in the air
                    if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime)) {
                        return currentTimeUTC >= depMinutes || currentTimeUTC <= arrMinutes;
                    }
                    return currentTimeUTC >= depMinutes && currentTimeUTC <= arrMinutes;
                });

                const remainingFlights = fetchedFlights.filter(flight => {
                    const depMinutes = timeToMinutes(flight.DepartureTime);
                    const arrMinutes = timeToMinutes(flight.ArrivalTime);

                    // For outbound flights, only check departure time
                    if (flight.Origin === 'ARN') {
                        return depMinutes > currentTimeUTC;
                    }

                    // For inbound flights, include overnight flights
                    if (flight.Destination === 'ARN') {
                        if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime) &&
                            isAfterMidnight(flight.ArrivalTime)) {
                            return true;
                        }
                        if (isOvernightFlight(flight.DepartureTime, flight.ArrivalTime)) {
                            return currentTimeUTC >= depMinutes || currentTimeUTC <= arrMinutes;
                        }
                        return currentTimeUTC <= arrMinutes;
                    }
                    return false;
                });

                const remainingInbound = remainingFlights.filter(flight => flight.Destination === 'ARN');
                const remainingOutbound = remainingFlights.filter(flight => flight.Origin === 'ARN');

                setFlightStats({
                    activeFlights: activeFlights.length,
                    todayFlights: fetchedFlights.length,
                    remainingInbound: remainingInbound.length,
                    remainingOutbound: remainingOutbound.length
                });
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch flight statistics');
                setLoading(false);
            }
        };

        fetchFlightStats();
        const interval = setInterval(fetchFlightStats, 60000);
        return () => clearInterval(interval);
    }, [token]);

    const stats = [
        {
            icon: <Plane className="w-6 h-6 text-blue-500" />,
            label: "Active Flights",
            value: loading ? "..." : String(flightStats.activeFlights),
            description: "Currently in the air",
            onClick: () => handleStatClick('active')
        },
        {
            icon: <Calendar className="w-6 h-6 text-green-500" />,
            label: "Today",
            value: loading ? "..." : String(flightStats.todayFlights),
            description: "Scheduled flights",
            onClick: () => handleStatClick('today')
        },
        {
            icon: <ArrowDownRight className="w-6 h-6 text-purple-500" />,
            label: "Remaining Inbound",
            value: loading ? "..." : String(flightStats.remainingInbound),
            description: "Arriving today",
            onClick: () => handleStatClick('inbound')
        },
        {
            icon: <ArrowUpRight className="w-6 h-6 text-orange-500" />,
            label: "Remaining Outbound",
            value: loading ? "..." : String(flightStats.remainingOutbound),
            description: "Departing today",
            onClick: () => handleStatClick('outbound')
        }
    ];

    const quickActions = [
        {
            title: "View Schedule",
            description: "Check upcoming flights and their details",
            icon: <Calendar className="w-5 h-5" />,
            action: () => navigate('/schedule'),
            color: "bg-blue-500"
        },
        {
            title: "Admin Dashboard",
            description: "Manage flights and system settings",
            icon: <Shield className="w-5 h-5" />,
            action: () => navigate('/admin'),
            color: "bg-purple-500",
            adminOnly: true
        }
    ];

    return (
        <div className="container mx-auto p-6 space-y-6">
            {/* Header section */}
            <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tight flex items-center gap-2">
                    <LayoutDashboard className="h-8 w-8 text-blue-500" />
                    <span className="bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
                        Dashboard
                    </span>
                </h1>
                <p className="text-muted-foreground">
                    Today's Statistics for ARN:
                </p>
            </div>

            {/* Error Alert */}
            {error && (
                <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            {/* Stats Cards */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat, index) => (
                    <Card
                        key={index}
                        className="cursor-pointer hover:shadow-md transition-shadow"
                        onClick={stat.onClick}
                    >
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                {stat.label}
                            </CardTitle>
                            {stat.icon}
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{stat.value}</div>
                            <p className="text-xs text-muted-foreground">
                                {stat.description}
                            </p>
                        </CardContent>
                    </Card>
                ))}
            </div>

            <Separator className="my-6" />

            {/* Base Operation Switch */}
            <Card className="mb-6">
                <CardContent className="pt-6">
                    <div className="flex items-center justify-between">
                        <div className="space-y-1">
                            <h3 className="font-medium">Flight View Settings</h3>
                            <p className="text-sm text-muted-foreground">
                                Toggle to show only base operated flights in all charts
                            </p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Switch
                                id="base-operated"
                                checked={showBaseOperated}
                                onCheckedChange={setShowBaseOperated}
                            />
                            <Label htmlFor="base-operated">Show Base Operated Only</Label>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Statistics Charts */}
            <div className="grid gap-4 md:grid-cols-2">
                {/* Daily Distribution Chart */}
                <Card>
                    <CardHeader>
                        <CardTitle>Daily Flight Distribution</CardTitle>
                        <CardDescription>Showing flight distribution across the day</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[350px]">
                            <ChartContainer config={dailyChartConfig}>
                                <BarChart data={prepareDailyDistribution(flights)}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="time"
                                        tickLine={false}
                                        axisLine={false}
                                        tickMargin={8}
                                    />
                                    <YAxis />
                                    <Bar
                                        dataKey="inbound"
                                        stackId="a"
                                        fill="hsl(var(--chart-1))"
                                    // Remove radius for stacked bars
                                    />
                                    <Bar
                                        dataKey="outbound"
                                        stackId="a"
                                        fill="hsl(var(--chart-2))"
                                    // Remove radius for stacked bars
                                    />
                                    <Tooltip
                                        content={({ active, payload, label }) => {
                                            if (!active || !payload || !payload[0]) return null;

                                            return (
                                                <div className="rounded-lg border bg-background p-2 shadow-sm">
                                                    <div className="grid gap-2">
                                                        <div className="text-xs text-muted-foreground">
                                                            {label}
                                                        </div>
                                                        <div className="grid gap-1">
                                                            {payload.map((entry: any, index: number) => (
                                                                <div key={index} className="flex items-center gap-2">
                                                                    <span
                                                                        className="h-3 w-3 rounded-full"
                                                                        style={{
                                                                            backgroundColor: entry.dataKey === 'inbound'
                                                                                ? 'hsl(var(--chart-1))'
                                                                                : 'hsl(var(--chart-2))'
                                                                        }}
                                                                    />
                                                                    <span className="text-sm font-medium">
                                                                        {entry.dataKey === 'inbound' ? 'Inbound' : 'Outbound'}: {entry.value}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                    <ChartLegend content={<ChartLegendContent chartConfig={dailyChartConfig} />} />
                                </BarChart>
                            </ChartContainer>
                        </div>
                    </CardContent>
                    <CardFooter className="flex justify-between">
                        <div className="flex items-center gap-2">
                            <TrendingUp className="h-4 w-4 text-green-500" />
                            <span className="text-sm text-muted-foreground">
                                {`${calculateTotalFlights(prepareDailyDistribution(flights))} total flights today`}
                            </span>
                        </div>
                    </CardFooter>
                </Card>

                {/* Monthly Distribution Chart */}
                <Card>
                    <CardHeader>
                        <div className="flex items-center justify-between">
                            <div>
                                <CardTitle>Monthly Overview</CardTitle>
                                <CardDescription>
                                    {selectedMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                                </CardDescription>
                            </div>
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={() => navigateMonth(-1)}
                                    disabled={monthlyLoading}
                                >
                                    <ArrowLeft className="h-4 w-4" />
                                </Button>
                                <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={() => navigateMonth(1)}
                                    disabled={monthlyLoading}
                                >
                                    <ArrowRight className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[300px]">
                            {monthlyLoading ? (
                                <div className="h-full w-full flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                                </div>
                            ) : (
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart data={monthlyFlights}>
                                        <defs>
                                            <linearGradient id="monthlyGradient" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.3} />
                                                <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="day"
                                            tickFormatter={(value: number) => String(value + 1)}  // Show 1-based days on axis
                                        />
                                        <YAxis
                                            allowDecimals={false}
                                            domain={[0, 'auto']}
                                        />
                                        <Tooltip
                                            content={({ active, payload, label }) => {
                                                if (!active || !payload || !payload[0]) return null;
                                                const date = new Date(
                                                    selectedMonth.getFullYear(),
                                                    selectedMonth.getMonth(),
                                                    Number(label) - 1  // Subtract 1 to show correct date for the data
                                                );
                                                return (
                                                    <div className="rounded-lg border bg-background p-2 shadow-sm">
                                                        <div className="grid gap-2">
                                                            <div className="text-xs text-muted-foreground">
                                                                {date.toLocaleDateString('en-US', {
                                                                    weekday: 'long',
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric'
                                                                })}
                                                            </div>
                                                            <div className="text-sm font-bold">
                                                                {payload[0].value !== undefined
                                                                    ? `${Math.round(Number(payload[0].value))} flights`
                                                                    : 'No data'
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                        <Area
                                            type="step"
                                            dataKey="flights"
                                            stroke="#3b82f6"
                                            fill="url(#monthlyGradient)"
                                            isAnimationActive={false}
                                            strokeWidth={2}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>

            <Separator className="my-6" />

            <FlightModal
                isOpen={modalState.isOpen}
                onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
                flights={modalState.flights}
                title={modalState.title}
                description={modalState.description}
            />

            {/* System Status Alert */}
            <Alert>
                <Bell className="h-4 w-4" />
                <AlertTitle>System Status</AlertTitle>
                <AlertDescription>
                    All systems are operational. Last update: {new Date().toLocaleTimeString()}
                </AlertDescription>
            </Alert>

            {/* Quick Actions */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2">
                {quickActions.map((action, index) => (
                    (!action.adminOnly || isAuthenticated) && (
                        <Card
                            key={index}
                            className="hover:shadow-lg transition-shadow cursor-pointer"
                            onClick={action.action}
                        >
                            <CardHeader>
                                <div className={`${action.color} w-10 h-10 rounded-lg flex items-center justify-center text-white mb-3`}>
                                    {action.icon}
                                </div>
                                <CardTitle className="flex justify-between items-center">
                                    {action.title}
                                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                                </CardTitle>
                                <CardDescription>
                                    {action.description}
                                </CardDescription>
                            </CardHeader>
                        </Card>
                    )
                ))}
            </div>

            {/* Admin Login Card */}
            {!isAuthenticated && (
                <Card className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
                    <CardHeader>
                        <CardTitle>Admin Login</CardTitle>
                        <CardDescription className="text-blue-100">
                            Log in to access admin dashboard
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Button
                            variant="secondary"
                            onClick={() => navigate('/login')}
                            className="hover:bg-white hover:text-blue-600"
                        >
                            Login Now
                            <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default Home;